/*
 * ChangeStorePage Messages
 *
 * This contains all the text for the ChangeStorePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ChangeStorePage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Chọn chi nhánh',
  },
  headerLabel: {
    id: `${scope}.headerLabel`,
    defaultMessage: 'Tạo cửa hàng',
  },
  storeImage: {
    id: `${scope}.storeImage`,
    defaultMessage: 'Hình ảnh cửa hàng',
  },
  disableAddingStore: {
    id: `${scope}.disableAddingStore`,
    defaultMessage: 'Đã có cửa hàng bị khóa trong tài khoản này',
  },
});
