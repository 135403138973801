/*
 * StoreList Messages
 *
 * This contains all the text for the StoreList component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.StoreList';

export default defineMessages({
  addNewStore: {
    id: `${scope}.addNewStore`,
    defaultMessage: 'Tạo mới cửa hàng',
  },
  lastOrder: {
    id: `${scope}.lastOrder`,
    defaultMessage: 'Đặt hàng lần cuối',
  },
  viewDetails: {
    id: `${scope}.viewDetails`,
    defaultMessage: 'Kiểm tra',
  },
  confirmed: {
    id: `${scope}.confirmed`,
    defaultMessage: 'Đã xác nhận',
  },
  hiddenStoresDescription: {
    id: `${scope}.hiddenStoresDescription`,
    defaultMessage: 'Những cửa hàng đã xóa hoặc bị từ chối đang được ẩn',
  },
  pendingStore: {
    id: `${scope}.pendingStore`,
    defaultMessage: 'Cửa hàng đang được duyệt',
  },
  PENDING: {
    id: `${scope}.PENDING`,
    defaultMessage: 'Đang duyệt',
  },
  BLOCKED: {
    id: `${scope}.BLOCKED`,
    defaultMessage: 'Đã chặn',
  },
  NORMAL: {
    id: `${scope}.NORMAL`,
    defaultMessage: 'Bình thường',
  },
});
