/*
 *
 * ChangeStorePage actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const buyer = createAsyncAction(
  ActionTypes.FETCH_BUYER_REQUEST,
  ActionTypes.FETCH_BUYER_SUCCESS,
  ActionTypes.FETCH_BUYER_FAILURE,
)<void, {}, Error>();
export const createStoreSubmit = (data: any) => action(ActionTypes.CREATE_STORE, data);
export const createStore = createAsyncAction(
  ActionTypes.CREATE_STORE_REQUEST,
  ActionTypes.CREATE_STORE_SUCCESS,
  ActionTypes.CREATE_STORE_FAILURE,
)<void, {}, Error>();
export const addStoreDisabled = createAsyncAction(
  ActionTypes.FETCH_ADD_STORE_DISABLED_REQUEST,
  ActionTypes.FETCH_ADD_STORE_DISABLED_SUCCESS,
  ActionTypes.FETCH_ADD_STORE_DISABLED_FAILURE,
)<void, {}, Error>();
