/**
 *
 * StoreList
 *
 */

import { PlusOutlined } from '@ant-design/icons';

import { Col, message, Row } from 'antd';
import React, { PureComponent } from 'react';

import { Box as BaseBox } from '../Box/Box';
import Button from 'components/Button/Button';
import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
import { FormattedMessage } from 'react-intl';
import Image from 'components/Image/Image';
import media from 'utils/mediaStyle';
import messages from './messages';
import moment from 'moment';
import styled from 'styled-components';
import { Store, StoreStatus } from 'types/schema';
import translations from 'translations';

interface OwnProps {
  stores: Store[];
  onChangeStore?: (s: Store) => void;
  onAddStore?: () => void;
  onShowReviewModal?: (index: number) => void;
  review?: boolean;
}

const Box = styled<any>(BaseBox)`
  display: flex;
  height: 240px;
  border-radius: 10px;
  cursor: pointer;
  background: #ffffff;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  flex: 1;
  ${media.md`
    flex-direction: row;
    align-items: space-between;
  `};
`;

const PlusContainer = styled.div`
  font-size: 36px;
  color: white;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: #dce0e9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6pt;
  overflow: hidden;
`;

const LastOrdered = styled.p`
  font-size: 12px;
  color: #8c8c8c;
`;

const Container = styled.div<any>`
  .ant-row {
    display: flex;
    flex-direction: column;
  }
  [class*='ant-col'] {
    width: 100%;
  }
  ${Box} {
    height: auto;
    padding: 14pt 8pt;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin-bottom: 12pt;

    @media (min-width: 575px) {
      padding: 14pt 18pt;
    }
  }
  ${Button} {
    padding: 0 16px;
    @media (min-width: 575px) {
      padding: 0 20px;
    }
  }
  ${PlusContainer} {
    margin-bottom: 0;
    margin-right: 12pt;
    width: 28px;
    height: 28px;
  }
  ${LastOrdered} {
    white-space: nowrap;
  }
`;

const InfoLeftBlock = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const StoreName = styled.h4`
  font-size: 14px;
  white-space: normal;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;

const Status = styled.div`
  ${({ status }) => {
    const statusColor = { PENDING: '#F9A825', BLOCKED: '#EF5350' };
    return `
      color: ${statusColor[status]}
    `;
  }}
`;

const HiddenStoresDescription = styled.p`
  color: #8c8c8c;
  text-align: center;
`;

const Note = styled.span`
  display: inline-block;
  background-color: #40a9ff;
  color: #fff;
  font-size: 10px;
  padding: 0 8px;
  border-radius: 2px;
  min-width: 80px;
  text-align: center;

  @media (min-width: 768px) {
    margin-left: 8px;
  }
`;

class StoreList extends PureComponent<OwnProps> {
  public onChangeStore = (s: Store) => () => {
    if (this.props.onChangeStore) {
      if (s.status === StoreStatus.Pending) {
        message.warning(translations(messages.pendingStore));
      } else {
        window.localStorage.setItem('store', s.id);
        this.props.onChangeStore(s);
      }
    }
  };

  public render() {
    const { stores } = this.props;
    const alternativeImage = (name) => `https://ui-avatars.com/api/?name=${name}&size=512`;
    return (
      <Container>
        <Row gutter={8}>
          <ErrorBoundary>
            {stores.map((s: any, index: number) => (
              <Col sm={24} md={24} lg={24} key={s.id}>
                <Box key={index} onClick={this.onChangeStore(s)}>
                  <PlusContainer>
                    <Image src={s.imageUrl || alternativeImage(s.name)} nopadding width={'28px'} height={'28px'} />
                  </PlusContainer>
                  <Info>
                    <InfoLeftBlock>
                      <StoreName>{s.name}</StoreName>
                      {s.review && (
                        <Note>
                          <FormattedMessage {...messages.confirmed} />
                        </Note>
                      )}
                      {s.lastOrderedAt && (
                        <LastOrdered>
                          <FormattedMessage {...messages.lastOrder} />: {moment(s.lastOrderedAt).format('DD-MM-YYYY')}
                        </LastOrdered>
                      )}
                    </InfoLeftBlock>
                    {s.status && s.status !== StoreStatus.Normal && (
                      <Status status={s.status}>
                        <FormattedMessage {...messages[s.status]} />
                      </Status>
                    )}
                  </Info>
                  {this.props.review && (
                    <Button
                      size="small"
                      type="primary"
                      ghost
                      onClick={() => this.props.onShowReviewModal && this.props.onShowReviewModal(index)}
                    >
                      <FormattedMessage {...messages.viewDetails} />
                    </Button>
                  )}
                </Box>
              </Col>
            ))}
          </ErrorBoundary>
          {!this.props.review && (
            <Col sm={24} md={24} lg={24}>
              <Box onClick={this.props.onAddStore}>
                <PlusContainer>
                  <PlusOutlined />
                </PlusContainer>
                <Info>
                  <StoreName>
                    <FormattedMessage {...messages.addNewStore} />
                  </StoreName>
                </Info>
              </Box>
              <HiddenStoresDescription>
                <FormattedMessage {...messages.hiddenStoresDescription} />
              </HiddenStoresDescription>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export default StoreList;
