import * as React from 'react';

import styled from 'styled-components';

const Header = styled.h1`
  color: #333333;
  font-weight: ${({ semibold }) => (semibold ? 500 : 600)};
  font-size: 22px;
  margin: 0;
  line-height: 26px;
`;

const Container = styled.div<any>`
  position: relative;
  display: inline-block;
  margin: 6px 0 20px;
  text-align: ${({ center }) => (center ? 'center' : '')};
  ${({ size, asComponent }) => {
    if (size === 'large') {
      return `
        ${asComponent ?? 'h1'} {
          font-size: 32px;
        }
      `;
    }
    return ``;
  }};
  @media (max-width: 767px) {
    ${({ noPadding }) => !noPadding && `padding: 0 10pt;`}
  }
`;

export default function (props: any) {
  const { asComponent, semibold, ...delegated } = props;
  return (
    <Container {...delegated}>
      <Header semibold={semibold} as={asComponent}>
        {props.children}
      </Header>
    </Container>
  );
}
