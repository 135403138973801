/*
 *
 * ChangeStorePage constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/ChangeStorePage/DEFAULT_ACTION',
  FETCH_BUYER_REQUEST = 'app/ChangeStorePage/FETCH_BUYER_REQUEST',
  FETCH_BUYER_SUCCESS = 'app/ChangeStorePage/FETCH_BUYER_SUCCESS',
  FETCH_BUYER_FAILURE = 'app/ChangeStorePage/FETCH_BUYER_FAILURE',
  CREATE_STORE = 'app/ChangeStorePage/create_Store',
  CREATE_STORE_REQUEST = 'app/ChangeStorePage/CREATE_STORE_REQUEST',
  CREATE_STORE_SUCCESS = 'app/ChangeStorePage/CREATE_STORE_SUCCESS',
  CREATE_STORE_FAILURE = 'app/ChangeStorePage/CREATE_STORE_FAILURE',
  FETCH_ADD_STORE_DISABLED_REQUEST = 'app/ChangeStorePage/FETCH_ADD_STORE_DISABLED_REQUEST',
  FETCH_ADD_STORE_DISABLED_SUCCESS = 'app/ChangeStorePage/FETCH_ADD_STORE_DISABLED_SUCCESS',
  FETCH_ADD_STORE_DISABLED_FAILURE = 'app/ChangeStorePage/FETCH_ADD_STORE_DISABLED_FAILURE',
}

export default ActionTypes;
