import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the changeStorePage state domain
 */

const selectChangeStorePageDomain = (state: ApplicationRootState) => {
  return state ? state.changeStorePage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by ChangeStorePage
 */

const selectChangeStorePage = () =>
  createSelector(selectChangeStorePageDomain, substate => {
    return substate;
  });

export default selectChangeStorePage;
export { selectChangeStorePageDomain };
